const amenities = [
    { name: "Ceiling Fan", fieldName: "amenities.ceilingFan" },
    { name: "Air Conditioner", fieldName: "amenities.airConditioner" },
    { name: "Parking Garage", fieldName: "amenities.parkingGarage" },
    { name: "Gym", fieldName: "amenities.gym" },
    { name: "Carpet Floor", fieldName: "amenities.carpetFloor" },
    { name: "Floor Tiles", fieldName: "amenities.floorTiles" },
    { name: "Smoking Allow", fieldName: "amenities.smokingAllowed" },
    { name: "Pet Allow", fieldName: "amenities.petAllow" },
    { name: "Party Allow", fieldName: "amenities.partyAllow" },
    { name: "Children Allow", fieldName: "amenities.childrenAllow" },
  ];
  
  
  const allowed = [
    {
      smoking_allowed: false,
      pet_allowed: false,
      party_allowed: false,
      children_allowed: false,
    },
  ];
  const types = [
    { value: "single-room", label: "Single Room" },
    { value: "single-room-self-contain", label: "Single Room (self-contain)" },
    { value: "one-bedroom-chamber-hall", label: "One bedroom (Chamber/Hall)" },
    { value: "one-bedroom-chamber-hall-sf", label: "One bedroom (Chamber/Hall SF)" },
    { value: "two-bedrooms", label: "Two bedrooms" },
    { value: "two-bedrooms-sf", label: "Two bedrooms (SF)" },
    { value: "three-bedrooms", label: "Three bedrooms" },
    { value: "three-bedrooms-sf", label: "Three bedrooms (SF)" },
    { value: "four-bedrooms", label: "Four bedrooms" },
    { value: "four-bedrooms-sf", label: "Four bedrooms (SF)" },
    { value: "apartment-complex", label: "Apartment Complex" },
    { value: "other", label: "Other" }
  ];
  
  export { allowed, amenities ,types};
  