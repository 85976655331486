/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import { useState, useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, Container, IconButton } from '@mui/material';
// redux
import { useSnackbar } from 'notistack';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import axiosInstance from '../../../utils/axios';
// import AccordionUsage from './Accordian';

// ----------------------------------------------------------------------

export default function Logs() {
  const { enqueueSnackbar } = useSnackbar();
  const [Data, setdata] = useState([]);
  const [loader, setloader] = useState(false);

  const GetAllAdmin = () => {
    setloader(true);
    axiosInstance
      .get('/logs')
      .then((response) => {
        if (response.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setloader(false);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 2,
        Cell: ({ row }) => (
          // eslint-disable-next-line prefer-template
          <p> {row?.original?.user?.first_name + ' ' + row?.original?.user?.last_name}</p>
        ),
      },
      {
        accessorKey: 'message',
        header: 'Message',
        size: 10,
      },
      {
        accessorKey: 'date',
        header: 'Date',
        size: 10,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  const data = [
    {
      id: 1,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 2,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 3,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 4,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 5,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 0,
    },
  ];

  useEffect(() => {
    setTableData(data);
  }, []);

  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.delete(`/subadmin/${rowdata}`).then((response) => {
        console.log(response);
        if (response?.data?.message === 'Your Subadmin has been deleted successfully') {
          enqueueSnackbar(response?.data?.message);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  async function handleApproved(id, hi) {
    try {
      //  console.log(user?.id);
      await axiosInstance.get(`/subadmin-status-change/${id}`).then((response) => {
        if (response) {
          console.log(response?.data, '=========');
          enqueueSnackbar(response?.data?.message);
          // reset();
          // navigate(PATH_DASHBOARD.dorm.dorm);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  return (
    <>
      <Page title="Logs">
        <Container maxWidth="lg">
          <HeaderBreadcrumbs
            heading="Logs"
            links={[{ name: '', href: '' }]}
            action={
              <></>
              // <Button
              //   variant="contained"
              //   startIcon={<Iconify icon="eva:plus-fill" />}
              //   component={RouterLink}
              //   to={'/dashboard/addsub-admin'}
              //   // to={''}
              // >
              //   New Sub-admin
              // </Button>
            }
          />

          <MaterialReactTable
            columns={columns}
            data={Data}
            // enableRowActions
            state={{ showSkeletons: loader }}
            // renderRowActions={({ row }) => (
            //   <Box
            //     sx={{
            //       display: 'flex',
            //       flexWrap: 'nowrap',
            //       gap: '10px',
            //       justifyContent: 'flex-center',
            //     }}
            //   >
            //     <IconButton
            //       color="primary"
            //       sx={{
            //         border: '1px solid',
            //         borderColor: 'primary.main',
            //       }}
            //       onClick={() => {
            //         navigate(`/dashboard/editsub-admin/${row?.original?.id}`, { state: row.original });
            //       }}
            //     >
            //       <EditIcon />
            //     </IconButton>

            //       <IconButton
            //         color="error"
            //         sx={{
            //           border: '1px solid',
            //           borderColor: 'error.main',
            //         }}
            //         onClick={() => {
            //           handleDelete(row.original.id);
            //         }}
            //       >
            //         <DeleteIcon />
            //       </IconButton>

            //   </Box>
            // )}
            // positionActionsColumn="last"
          />
        </Container>
      </Page>
      {/* <AccordionUsage/> */}
    </>
  );
}
