import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
import { RegisterForm } from '../sections/auth/register';
// components
import LoadingScreen from '../components/LoadingScreen';
import Websitechange from '../pages/dashboard/Websitechanges/Websitechange';
import Logos from '../pages/dashboard/Websitechanges/Logos';
// import Catogery from '../pages/dashboard/Catogery';
import Addcategory from '../pages/dashboard/Addcategory';
import Editcategory from '../pages/dashboard/Editcategory';
// import AddServices from '../pages/dashboard/AddServices';
// import EditServices from '../pages/dashboard/EditServices';
import Services from '../pages/dashboard/Service';
import Message from '../pages/dashboard/Message';
import ResetPassword from '../pages/auth/ResetPassword';
import CompanyProfile from '../pages/dashboard/Companyprofile';
import EditHost from '../pages/dashboard/Host/EditHost';
import AddHost from '../pages/dashboard/Host/AddHost';

import AddSubadmin from '../pages/dashboard/Subadmin/AddSubadmin';
import EditSubadmin from '../pages/dashboard/Subadmin/EditSubadmin';
import Host from '../pages/dashboard/Host/Index';
import Subadmin from '../pages/dashboard/Subadmin/Index';
import EditUser from '../pages/dashboard/User/EditUser';
import AddUser from '../pages/dashboard/User/AddUser';
import ViewListing from '../pages/dashboard/Listing/ViewListing';
import Home from '../pages/dashboard/Home/Home';
import ViewHost from '../pages/dashboard/Host/Viewpermission';
import AddListing from '../pages/dashboard/AddServices';
import EditListing from '../pages/dashboard/EditListing';
import Features from '../pages/dashboard/Features/Features';
import AddFeature from '../pages/dashboard/Features/AddFetaures';
import EditFeature from '../pages/dashboard/Features/EditFeatures';
import Type from '../pages/dashboard/Type/Type';
import AddType from '../pages/dashboard/Type/AddType';
import EditType from '../pages/dashboard/Type/EditType';
import Title from '../pages/dashboard/Title/Title';
import AddTitle from '../pages/dashboard/Title/AddTitle';
import EditTitle from '../pages/dashboard/Title/EditTitle';
import Contact from '../pages/dashboard/Contact/Contact';
import Advertisement from '../pages/dashboard/Advertisement/Advertisement';
import Editadvertisement from '../pages/dashboard/Advertisement/Editadvertisement';
import Addadvertisement from '../pages/dashboard/Advertisement/Addadvertisement';
import Logs from '../pages/dashboard/Logs/Logs';
import BannerImage from '../pages/dashboard/BannerImage/BannerImage';
import AddBannerImage from '../pages/dashboard/BannerImage/AddBannerimage';
import ReportListing from '../pages/dashboard/Report/Report';
import ReviewListing from '../pages/dashboard/Reviews/Review';
import About from '../pages/dashboard/Company/About';
import Careers from '../pages/dashboard/Company/Careers';
import RentalGuides from '../pages/dashboard/Company/RentalGuides';
import OurContact from '../pages/dashboard/Company/OurContact';
import { PATH_DASHBOARD } from './paths';
import BlogCom from '../pages/dashboard/Company/Blog';
import AddAbout from '../pages/dashboard/Company/AddAbout';
import AddBlog from '../pages/dashboard/Company/AddBlog';
import AddRentalGuides from '../pages/dashboard/Company/AddRentalGuides';
import Editblog from '../pages/dashboard/Company/Editblog';
import Faq from '../pages/dashboard/Support/Faqs';
import Addfaq from '../pages/dashboard/Support/AddFaqs';
import Editfaq from '../pages/dashboard/Support/Editfaq';
import PrivacyPolicy from '../pages/dashboard/Support/PrivacyPolicy';
import Termuse from '../pages/dashboard/Support/Termuse';
import ReportIssue from '../pages/dashboard/Support/ReportIssue';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Home /> },
    { path: '/admin/login', element: <Login /> },
    { path: '/subadmin/login', element: <LoginAgent /> },
    { path: '/auth/reset-password', element: <ResetPassword /> },
    { path: '/auth/reset', element: <RegisterForm /> },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'website', element: <Website /> },
        { path: 'subadmin', element: <Subadmin /> },
        { path: 'host', element: <Host /> },
        { path: 'viewuser/:id', element: <ViewHost /> },
        { path: 'demo', element: <Demo /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'addsub-admin', element: <AddSubadmin /> },
        { path: 'editsub-admin/:id', element: <EditSubadmin /> },
        { path: 'location', element: <Locations /> },
        { path: 'editlocation/:id', element: <EditLocation /> },
        { path: 'addlocation', element: <AddLocation /> },
        { path: 'logs', element: <Logs /> },
        // 1
        { path: 'feature', element: <Features /> },
        { path: 'editfeature/:id', element: <EditFeature /> },
        { path: 'addfeature', element: <AddFeature /> },
        // 2
        { path: 'type', element: <Type /> },
        { path: 'edittype/:id', element: <EditType /> },
        { path: 'addtype', element: <AddType /> },
        { path: 'title', element: <Title /> },
        { path: 'edittitle/:id', element: <EditTitle /> },
        { path: 'addtitle', element: <AddTitle /> },
        { path: PATH_DASHBOARD.company.aboutus, element: <About /> },
        { path: PATH_DASHBOARD.company.addabout, element: <AddAbout /> },
        { path: 'editabout/:id', element: <AddAbout /> },
        { path: PATH_DASHBOARD.company.Careers, element: <Careers /> },
        { path: PATH_DASHBOARD.company.Blog, element: <BlogCom /> },
        { path: 'editblog/:id', element: <Editblog /> },
        { path: PATH_DASHBOARD.company.addblog, element: <AddBlog /> },
        { path: PATH_DASHBOARD.company.Rental, element: <RentalGuides /> },
        { path: PATH_DASHBOARD.company.Our_contact, element: <OurContact /> },
        { path: PATH_DASHBOARD.company.Our_contact, element: <AddRentalGuides /> },
        { path: PATH_DASHBOARD.Support.FAQs, element: <Faq /> },
        { path: PATH_DASHBOARD.Support.addfaq, element: <Addfaq /> },
        { path: PATH_DASHBOARD.Support.Privacy, element: <PrivacyPolicy /> },
        { path: PATH_DASHBOARD.Support.Terms, element: <Termuse /> },
        { path: PATH_DASHBOARD.Support.report, element: <ReportIssue /> },
        { path: 'editfaq/:id', element: <Editfaq /> },
        // 3
        { path: 'advertisement', element: <Advertisement /> },
        { path: 'banner-image', element: <BannerImage /> },
        { path: 'add-banner-image', element: <AddBannerImage /> },
        { path: 'report-lisitng', element: <ReportListing /> },
        { path: 'review-lisitng', element: <ReviewListing /> },
        { path: 'editadvertisement/:id', element: <Editadvertisement /> },
        { path: 'addadvertisement', element: <Addadvertisement /> },
        { path: 'location', element: <Locations /> },
        { path: 'contact', element: <Contact /> },
        { path: 'editlocation/:id', element: <EditLocation /> },
        { path: 'addlocation', element: <AddLocation /> },
        { path: 'addhost', element: <AddHost /> },
        { path: 'edithost/:id', element: <EditHost /> },
        { path: 'adduser', element: <AddUser /> },
        { path: 'addlisting', element: <AddListing /> },
        { path: 'users', element: <User /> },
        { path: 'edituser/:id', element: <EditUser /> },
        { path: 'viewlisting/:id', element: <ViewListing /> },
        { path: 'profile', element: <CompanyProfile /> },
        { path: 'websitechanges/:id', element: <Websitechange /> },
        { path: 'logo/:id', element: <Logos /> },
        { path: 'message/:id', element: <Message /> },
        { path: 'services', element: <Services /> },
        { path: 'addcategory/:id', element: <Addcategory /> },
        { path: 'editcategory/:id', element: <Editcategory /> },
        { path: 'editlisting/:id', element: <EditListing /> },
        // { path: 'addservices', element: <AddServices /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        {
          path: 'Company',
          children: [
            { element: <Navigate to="/dashboard/weedowl/product" replace />, index: true },
            { path: 'product', element: <Product /> },
            { path: 'singleproduct/add', element: <NewProduct /> },
            { path: 'singleproduct/edit:id', element: <EditProduct /> },
            { path: 'category', element: <Category /> },
            { path: 'user', element: <User /> },
            { path: 'order', element: <Order /> },
            { path: 'offer', element: <Offer /> },
            { path: 'offer/product:id', element: <Productmanage /> },
            { path: 'newsletter', element: <NewLetter /> },
            { path: 'newsletter/add', element: <NewLetterAdd /> },
            { path: 'newsletter/edit:id', element: <NewLetterEdit /> },
          ],
        },
        {
          path: 'Weedowl',
          children: [
            { element: <Navigate to="/dashboard/weedowl/product" replace />, index: true },
            { path: 'product', element: <Product /> },
            { path: 'singleproduct/add', element: <NewProduct /> },
            { path: 'singleproduct/edit:id', element: <EditProduct /> },
            { path: 'category', element: <Category /> },
            { path: 'user', element: <User /> },
            { path: 'order', element: <Order /> },
            { path: 'offer', element: <Offer /> },
            { path: 'offer/product:id', element: <Productmanage /> },
            { path: 'newsletter', element: <NewLetter /> },
            { path: 'newsletter/add', element: <NewLetterAdd /> },
            { path: 'newsletter/edit:id', element: <NewLetterEdit /> },
          ],
        },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const LoginAgent = Loadable(lazy(() => import('../pages/auth/LoginAgent')));

// DASHBOARD

// GENERAL
// const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const Website = Loadable(lazy(() => import('../pages/dashboard/Website')));
// const Index = Loadable(lazy(() => import('../pages/dashboard/Subadmin/Index')));
// const Viewpermission = Loadable(lazy(() => import('../pages/dashboard/Subadmin/Viewpermission')));
const Demo = Loadable(lazy(() => import('../pages/dashboard/Demo')));
//  weedowl
const Product = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Product/Index')));
const NewProduct = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Product/Addproduct')));
const EditProduct = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Product/Editproduct')));
const Category = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Catagory/Index')));
const User = Loadable(lazy(() => import('../pages/dashboard/User/Index')));
const Order = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Order/Index')));
const Offer = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Offer/Index')));
const Productmanage = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Offer/Productmanage')));
const NewLetter = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Newsletter/Index')));
const NewLetterAdd = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Newsletter/AddnewsLetter')));
const NewLetterEdit = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Newsletter/UpdateNewsletter')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const EditLocation = Loadable(lazy(() => import('../pages/dashboard/Location/EditLocation')));
const AddLocation = Loadable(lazy(() => import('../pages/dashboard/Location/AddLocation')));
const Locations = Loadable(lazy(() => import('../pages/dashboard/Location/Locatioon')));
// Location
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// MAIN
// const HomePage = Loadable(lazy(() => import('../pages/Home')));
// const About = Loadable(lazy(() => import('../pages/About')));
// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
