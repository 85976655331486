/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { Card, Grid, Stack, Container, Box, Typography, Input, TextField } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

// Define image and link keys
const imageKeys = [
  'image_one',
  'image_two',
  // 'image_three',
  // 'image_four',
  // 'image_five',
  // 'image_six',
  // 'image_seven',
  // 'image_eight',
  // 'image_nine',
];
const linkKeys = [
  'link_one',
  'link_two',
  // 'link_three',
  // 'link_four',
  // 'link_five',
  // 'link_six',
  // 'link_seven',
  // 'link_eight',
  // 'link_nine',
];

export default function AddBannerImage() {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const GetAllAdmin = async () => {
    try {
      const response = await axiosInstance.get('/image-setting');
      if (response.data.data) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetAllAdmin();
  }, []);

  const validationSchema = Yup.object().shape({
    images: Yup.array().of(Yup.mixed().required('Image is required')).min(1, 'At least one image is required'),
    links: Yup.array().of(Yup.string().url('Invalid URL')).min(1, 'At least one link is required'),
  });

  // Set default values for the form
  const defaultValues = useMemo(() => {
    const values = {};
    imageKeys.forEach((key) => {
      values[key] = null;
    });
    linkKeys.forEach((key, index) => {
      values[key] = data?.[0]?.[key];
    });
    return values;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);
  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
    control,
  } = methods;

  const onSubmit = async (formData) => {
    try {
      const fd = new FormData();
      imageKeys.forEach((key) => {
        const file = formData[key];
        if (file) {
          fd.append(key, file);
        }
      });
      linkKeys.forEach((key) => {
        const link = formData[key];
        if (link) {
          fd.append(key, link);
        }
      });

      const response = await axiosInstance.post('/image-setting', fd);
      if (response.data?.message) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        reset();
        navigate('/dashboard/banner-image');
      }
    } catch (error) {
      enqueueSnackbar(error?.message || 'An error occurred', { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs
        heading="Edit Banner Images"
        links={[{ name: 'Banner Images', href: '/dashboard/banner-images' }, { name: 'Edit Banner Images' }]}
      />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            {imageKeys.map((key, index) => (
              <Grid item xs={12} key={key}>
                <Card sx={{ p: 3 }} style={{ boxShadow: 'none' }}>
                  <Stack>
                    <Box sx={{ mb: -3 }}>
                      <Typography variant="subtitle2">Banner Image {index + 1}</Typography>
                      <Controller
                        name={key}
                        control={control}
                        render={({ field }) => (
                          <Input
                            style={{ width: '100%' }}
                            type="file"
                            inputProps={{ accept: 'image/*' }}
                            onChange={(e) => {
                              setValue(key, e.target.files[0]);
                            }}
                          />
                        )}
                      />
                      <Controller
                        name={linkKeys[index]}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            sx={{ mt: 5 }}
                            {...field}
                            label={`Link ${index + 1}`}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                          />
                        )}
                      />
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            ))}
            <Grid item xs={12}>
              <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                Edit Banner Images
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Container>
  );
}
