/* eslint-disable no-unused-vars */

/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import MaterialReactTable from 'material-react-table';
import toast, { Toaster } from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Card, Button, Container, Box, Tooltip, IconButton } from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import { PATH_DASHBOARD } from '../../routes/paths';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// import { getProducts } from '../../redux/Weedowl/Weedowlproduct';
import axiosInstance from '../../utils/axios';

export default function Services() {
  const storedUser = JSON.parse(localStorage.getItem('currentuser'));
  const isadmin = storedUser.role === 'admin';
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const { themeStretch } = useSettings();
  const [loader, setloader] = useState(false);
  const navigate = useNavigate();
  const GetYear = () => {
    setloader(true);
    axiosInstance
      .get('/listing')
      .then((response) => {
        if (response?.data) {
          setdata(response?.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        setloader(true);
        console.error(error);
      });
  };
  useEffect(() => {
    GetYear();
  }, []);
  const handleApproved = async (id) => {
    await axiosInstance
      .get(`/listing-status-change/${id}`)
      .then((res) => {
        console.log(res, 'res');
        if (res.data?.status === true) {
          toast.success(res?.data?.message);
          GetYear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFeature = async (id) => {
    await axiosInstance
      .get(`/listing-feature-status-change/${id}`)
      .then((res) => {
        console.log(res, 'res');
        if (res.data?.status === true) {
          toast.success(res?.data?.message);
          GetYear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Data = [
    {
      id: 1,
      name: 'Room 1',
      price: 100,
      mad_price: 120,
      eur_price: 150,
      description: 'This is a Room 1',
    },
  ];
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 50,
      },

      {
        accessorKey: 'title',
        header: 'title',
        size: 10,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 10,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 10,
        Cell: ({ row }) => (
          // eslint-disable-next-line prefer-template
          <p> {row?.original?.description?.slice(0, 25)+'...'}</p>
        ),
      },
      {
        accessorKey: 'created_at',
        header: 'TimeStamp',
        size: 10,
      },
      // {
      //   accessorKey: 'active_by',
      //   header: 'Active By',
      //   size: 10,
      // },
      {
        accessorKey: 'city',
        header: 'City',
        size: 10,
      },

      {
        accessorKey: 'feature',
        header: 'Featured',
        size: 10,
        Cell: ({ row }) =>
          row?.original?.is_featured === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                handleFeature(row?.original?.id, 0);
              }}
            >
              {/* {row.original} */}
              {/* {console.log(row)} */}
              Featured
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                handleFeature(row?.original?.id, 1);
              }}
            >
              not featured
            </Button>
          ),
      },
      {
        accessorKey: 'is_active',
        header: 'Status',
        size: 10,
        Cell: ({ row }) =>
          row?.original?.is_active === 1 ? (
            <Button
              fullWidth
              variant="contained"
              color="success"
              endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}
              onClick={() => {
                handleApproved(row?.original?.id, 0);
              }}
            >
              {/* {row.original} */}
              {/* {console.log(row)} */}
              Active
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="error"
              endIcon={<Iconify icon={'eva:close-circle-fill'} />}
              onClick={() => {
                handleApproved(row?.original?.id, 1);
              }}
            >
              Inactive
            </Button>
          ),
      },
      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        Cell: ({ row }) => {
          const id = row.original.id;

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="left" title="View Listings">
                <IconButton onClick={() => navigate(`/dashboard/viewlisting/${id}`, { state: row?.original })}>
                  <RemoveRedEye />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => navigate(`/dashboard/editlisting/${id}`, { state: row?.original })}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              {storedUser?.role === 'admin' && (
                <Tooltip arrow placement="right" title="Delete">
                  <IconButton color="error" onClick={() => handledelete(id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handledelete = async (id) => {
    await axiosInstance
      .delete(`/listing/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Deleted Succesfully');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Page title="Rentisready: Category And Services">
        <Toaster position="top-right" reverseOrder={false} />
        <Container maxWidth={themeStretch ? false : 24}>
          <HeaderBreadcrumbs
            heading="Listings"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              {
                name: 'Listings',
              },
            ]}
            action={
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={PATH_DASHBOARD.listing.addlisting}
              >
                New Listing
              </Button>
            }
          />

          <Card>
            <MaterialReactTable
              columns={columns}
              data={data}
              state={{
                showSkeletons: loader,
                // columnVisibility: {
                //   active_by: isadmin,
                // },
              }}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
