/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import { useState, useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import { Delete as DeleteIcon, RemoveRedEye } from '@mui/icons-material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, Container, IconButton, Tooltip } from '@mui/material';
// redux
import { useSnackbar } from 'notistack';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import axiosInstance from '../../../utils/axios';
// import AccordionUsage from './Accordian';

// ----------------------------------------------------------------------

export default function BannerImage() {
  const storedUser = JSON.parse(localStorage.getItem('currentuser'));
  const { enqueueSnackbar } = useSnackbar();
  const [Data, setdata] = useState([]);
  const [loader, setloader] = useState(false);
  console.log(Data);
  const GetAllAdmin = () => {
    setloader(true);
    axiosInstance
      .get('/image-setting')
      .then((response) => {
        if (response.data.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setloader(false);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, []);
  const isadmin = storedUser.role === 'admin';
  // console.log(isadmin, 'isadmin');
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 5,
      },
      {
        accessorKey: 'image_one',
        header: 'image_one',
        size: 2,
        Cell: ({ row }) => (
          <img
            src={`${row.original?.image_one}`}
            alt="host"
            style={{ width: '60px', height: '60px', borderRadius: '15px' }}
          />
        ),
      },
      {
        accessorKey: 'link_one',
        header: 'Link One',
        size: 10,
        Cell: ({ row }) => (
          // eslint-disable-next-line react/self-closing-comp, react/jsx-no-target-blank, react/no-invalid-html-attribute
          <a href={`${row?.original?.link_one}`} target="_blank" rel="hello">
            {row?.original?.link_one}
          </a>
        ),
      },
      {
        accessorKey: 'delete',
        header: 'Delete First Image',
        size: 10,
        Cell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              gap: '10px',
              justifyContent: 'flex-center',
            }}
          >
            {row?.original?.image_one && (
              <Tooltip arrow placement="right" title="Delete One">
                <IconButton color="error" onClick={() => handleDelete('image_one')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      },
      {
        accessorKey: 'image_two',
        header: 'image_two',
        size: 2,
        Cell: ({ row }) => (
          <img
            src={`${row.original?.image_two}`}
            alt="host"
            style={{ width: '60px', height: '60px', borderRadius: '15px' }}
          />
        ),
      },
      {
        accessorKey: 'link_two',
        header: 'Link Two',
        size: 10,
        Cell: ({ row }) => (
          // eslint-disable-next-line react/self-closing-comp, react/jsx-no-target-blank, react/no-invalid-html-attribute
          <a href={`${row?.original?.link_two}`} target="_blank" rel="hello">
            {row?.original?.link_two}
          </a>
        ),
      },
      {
        accessorKey: 'delete',
        header: 'Delete Second Image',
        size: 10,
        Cell: ({ row }) => (
          
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              gap: '10px',
              justifyContent: 'flex-center',
            }}
          >
                {row?.original?.image_two && (
            <Tooltip arrow placement="right" title="Delete One">
              <IconButton color="error" onClick={() => handleDeletes('image_two')}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
                )}
          </Box>
        ),
      },

      // {
      //   accessorKey: 'image_three',
      //   header: 'image_three',
      //   size: 2,
      //   Cell: ({ row }) => (
      //     <img
      //       src={`${row.original?.image_three}`}
      //       alt="host"
      //       style={{ width: '60px', height: '60px', borderRadius: '15px' }}
      //     />
      //   ),
      // },
      // {
      //   accessorKey: 'link_three',
      //   header: 'Link Three',
      //   size: 10,
      //   Cell: ({ row }) => (
      //     // eslint-disable-next-line react/self-closing-comp, react/jsx-no-target-blank, react/no-invalid-html-attribute
      //     <a href={`${row?.original?.link_three}`} target="_blank" rel="hello">
      //       {row?.original?.link_three}
      //     </a>
      //   ),
      // },
      // {
      //   accessorKey: 'image_four',
      //   header: 'image_four',
      //   size: 2,
      //   Cell: ({ row }) => (
      //     <img
      //       src={`${row.original?.image_four}`}
      //       alt="host"
      //       style={{ width: '60px', height: '60px', borderRadius: '15px' }}
      //     />
      //   ),
      // },
      // {
      //   accessorKey: 'link_four',
      //   header: 'Link four',
      //   size: 10,
      //   Cell: ({ row }) => (
      //     // eslint-disable-next-line react/self-closing-comp, react/jsx-no-target-blank, react/no-invalid-html-attribute
      //     <a href={`${row?.original?.link_four}`} target="_blank" rel="hello">
      //       {row?.original?.link_four}
      //     </a>
      //   ),
      // },
      // {
      //   accessorKey: 'image_five',
      //   header: 'image_five',
      //   size: 2,
      //   Cell: ({ row }) => (
      //     <img
      //       src={`${row.original?.image_five}`}
      //       alt="host"
      //       style={{ width: '60px', height: '60px', borderRadius: '15px' }}
      //     />
      //   ),
      // },
      // {
      //   accessorKey: 'link_five',
      //   header: 'Link five',
      //   size: 10,
      //   Cell: ({ row }) => (
      //     // eslint-disable-next-line react/self-closing-comp, react/jsx-no-target-blank, react/no-invalid-html-attribute
      //     <a href={`${row?.original?.link_five}`} target="_blank" rel="hello">
      //       {row?.original?.link_five}
      //     </a>
      //   ),
      // },
      // {
      //   accessorKey: 'image_six',
      //   header: 'image_six',
      //   size: 2,
      //   Cell: ({ row }) => (
      //     <img
      //       src={`${row.original?.image_six}`}
      //       alt="host"
      //       style={{ width: '60px', height: '60px', borderRadius: '15px' }}
      //     />
      //   ),
      // },
      // {
      //   accessorKey: 'link_six',
      //   header: 'Link six',
      //   size: 10,
      //   Cell: ({ row }) => (
      //     // eslint-disable-next-line react/self-closing-comp, react/jsx-no-target-blank, react/no-invalid-html-attribute
      //     <a href={`${row?.original?.link_six}`} target="_blank" rel="hello">
      //       {row?.original?.link_six}
      //     </a>
      //   ),
      // },
      // {
      //   accessorKey: 'image_seven',
      //   header: 'image_seven',
      //   size: 2,
      //   Cell: ({ row }) => (
      //     <img
      //       src={`${row.original?.image_seven}`}
      //       alt="host"
      //       style={{ width: '60px', height: '60px', borderRadius: '15px' }}
      //     />
      //   ),
      // },
      // {
      //   accessorKey: 'link_seven',
      //   header: 'Link seven',
      //   size: 10,
      //   Cell: ({ row }) => (
      //     // eslint-disable-next-line react/self-closing-comp, react/jsx-no-target-blank, react/no-invalid-html-attribute
      //     <a href={`${row?.original?.link_seven}`} target="_blank" rel="hello">
      //       {row?.original?.link_seven}
      //     </a>
      //   ),
      // },
      // {
      //   accessorKey: 'image_eight',
      //   header: 'image_eight',
      //   size: 2,
      //   Cell: ({ row }) => (
      //     <img
      //       src={`${row.original?.image_eight}`}
      //       alt="host"
      //       style={{ width: '60px', height: '60px', borderRadius: '15px' }}
      //     />
      //   ),
      // },
      // {
      //   accessorKey: 'link_eight',
      //   header: 'Link eight',
      //   size: 10,
      //   Cell: ({ row }) => (
      //     // eslint-disable-next-line react/self-closing-comp, react/jsx-no-target-blank, react/no-invalid-html-attribute
      //     <a href={`${row?.original?.link_eight}`} target="_blank" rel="hello">
      //       {row?.original?.link_eight}
      //     </a>
      //   ),
      // },
      // {
      //   accessorKey: 'image_nine',
      //   header: 'image_nine',
      //   size: 2,
      //   Cell: ({ row }) => (
      //     <img
      //       src={`${row.original?.image_nine}`}
      //       alt="host"
      //       style={{ width: '60px', height: '60px', borderRadius: '15px' }}
      //     />
      //   ),
      // },
      // {
      //   accessorKey: 'link_nine',
      //   header: 'Link nine',
      //   size: 10,
      //   Cell: ({ row }) => (
      //     // eslint-disable-next-line react/self-closing-comp, react/jsx-no-target-blank, react/no-invalid-html-attribute
      //     <a href={`${row?.original?.link_nine}`} target="_blank" rel="hello">
      //       {row?.original?.link_nine}
      //     </a>
      //   ),
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  const data = [
    {
      id: 1,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 2,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 3,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 4,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 1,
    },
    {
      id: 5,
      firstname: 'John',
      lastname: 'Doe',
      email: 'yHbQH@example.com',
      is_active: 0,
    },
  ];

  useEffect(() => {
    setTableData(data);
  }, []);

  async function handleDelete(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.get(`/image-delete/${rowdata}`).then((response) => {
        console.log(response);
        if (response?.data?.message) {
          enqueueSnackbar('Deleted Sucessfully');
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  async function handleDeletes(rowdata) {
    console.log(rowdata);
    try {
      await axiosInstance.get(`/image-delete/${rowdata}`).then((response) => {
        console.log(response);
        if (response?.data?.message) {
          enqueueSnackbar('Deleted Sucessfully');
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  async function handleApproved(id) {
    try {
      await axiosInstance.get(`/user-status-change/${id}`).then((response) => {
        if (response) {
          enqueueSnackbar(response?.data?.message);
          GetAllAdmin();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  return (
    <>
      <Page title="Banner Image">
        <Container maxWidth="lg">
          <HeaderBreadcrumbs
            heading="Banner Image"
            links={[{ name: '', href: '' }]}
            action={
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={'/dashboard/add-banner-image'}
                // to={''}
              >
                Banner Image
              </Button>
            }
          />

          <MaterialReactTable
            columns={columns}
            data={Data}
            // enableRowActions
            state={{
              showSkeletons: loader,
              // columnVisibility: {
              //   verified_by: isadmin,
              // },
            }}

            // positionActionsColumn="last"
          />
        </Container>
      </Page>
      {/* <AccordionUsage/> */}
    </>
  );
}
