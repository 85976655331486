/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable dot-notation */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';
import React, { useMemo, useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import ImageUploading from 'react-images-uploading';
import { useLocation, useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Card, Grid, Stack, Container, Box, FormControlLabel, Checkbox, Button, MenuItem } from '@mui/material';
import axios from 'axios';
import { PATH_DASHBOARD } from '../../routes/paths';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { FormProvider, RHFTextField, RHFSelect } from '../../components/hook-form';
import { amenities, types } from './Amenities';
import RHFDescription from '../../components/hook-form/RHFDescription';
import axiosInstance from '../../utils/axios';

export default function EditListing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedCheckboxes((prevSelected) =>
      checked ? [...prevSelected, value] : prevSelected.filter((item) => item !== value)
    );
  };

  const [images, setImages] = useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    const updated = {
      ...imageList[addUpdateIndex[0]],
      ...images[addUpdateIndex[0]],
    };
    imageList[addUpdateIndex[0]] = updated;
    setImages(imageList);
  };
  const onImageRemoves = (index, image) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
    axiosInstance
      .delete(`/listing-image-destroy/${image?.id}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setImages(state?.listing_images);
  }, []);

  const NewProviderSchema = Yup.object().shape({
    title: Yup.string().required('Property Title is required'),
    price: Yup.string().required('Price is required'),
    description: Yup.string().required('Property Description is required'),
    type: Yup.string().required('Type is required'),
    country: Yup.string().required('Country is required'),
    region: Yup.string().required('Region is required'),
    city: Yup.string().required('City is required'),
    suburb: Yup.string().required('Suburb is required'),
    district: Yup.string().required('district is required'),
    address: Yup.string(),
    lease_term: Yup.string().required('Lease Term is required'),
  });

  const defaultValues = useMemo(
    () => ({
      title: state?.title || '',
      description: state?.description || '',
      price: state?.price || '',
      lease_term: state?.lease_term || '',
      type: state?.type || '',
      country: state?.country || '',
      region: state?.region || '',
      address: state?.address || '',
      city: state?.city || '',
      suburb: state?.suburb || '',
      district: state?.district || '',
    }),
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewProviderSchema),
    defaultValues,
  });
  const token = localStorage.getItem('accessToken');
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = async (data) => {
    const Data = new FormData();
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        Data.append(key, data[key]);
      }
    }

    Data.append('amenities', JSON.stringify(selectedCheckboxes));
    const image = [];

    images?.map((item) => {
      if ((item?.file && item?.id) || (item?.file && item?.id == undefined)) {
        image.push(item?.file);
      }
    });
    image?.map((item, index) => {
      Data.append(`images[${index}][file]`, item);
    });

    try {
      await axios
        .post(`https://api.rentisready.com/api/listing/${state?.id}`, Data, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'X-Http-Method-Override': 'PUT',
          },
        })
        .then((response) => {
          if (response?.data?.status === true) {
            enqueueSnackbar('Listing Updated Successfully');
            reset();
            navigate(PATH_DASHBOARD.listing.listing);
          }
        });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs heading="Edit Listing" links={[{ name: '', href: '' }]} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} mt={5}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                <Grid item xs={12}>
                  <RHFTextField name="title" label="Title" />
                </Grid>

                <Grid item xs={12}>
                  <RHFSelect
                    name={'type'}
                    label="Listing Type"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {
                      // eslint-disable-next-line arrow-body-style
                      types?.map((e) => {
                        return (
                          <MenuItem key={e.value} value={e.value}>
                            {e.label}
                          </MenuItem>
                        );
                      })
                    }
                  </RHFSelect>

                  {/* </RHFSelect> */}
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="price" label="Price" type="number" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="country" label="Country" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="region" label="Region" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="district" label="District" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="city" label="City" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="suburb" label="Suburb" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="address" label="Street And LandMark (Opitional)" />
                </Grid>

                <Grid item xs={12}>
                  <RHFDescription name="lease_term" label="Lease Term " multiline rows={3} />
                </Grid>
                <Grid item xs={12}>
                  <RHFDescription name="description" label="Description" multiline rows={3} />
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                }}
              >
                <h2 style={{ marginTop: '20px', marginBottom: '0px' }}>Aminities</h2>
                <Grid item xs={12}>
                  {amenities?.map((option) => (
                    <FormControlLabel
                      style={{ width: '220px' }}
                      key={option}
                      control={
                        <Checkbox
                          checked={selectedCheckboxes.includes(option?.name)}
                          onChange={handleCheckboxChange}
                          value={option?.name}
                        />
                      }
                      label={option?.name}
                    />
                  ))}
                </Grid>
              </Box>

              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  marginTop: 4,
                  alignItems: 'center',
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
                  {({ imageList, onImageUpload, isDragging, dragProps, onImageUpdate }) => (
                    <Box
                      sx={{
                        position: 'relative',
                        '& button': {
                          border: 'none',
                          background: isDragging ? '#2e3f6e' : 'none',
                          backgroundSize: 'cover',
                          backgroundColor: '#2e3f6e',
                          width: '100%',
                          height: 120,
                          color: isDragging ? 'white' : 'white',
                        },
                      }}
                    >
                      <Button onClick={onImageUpload} {...dragProps} fullWidth>
                        Click or Drop here <CloudUploadIcon className="ms-2" />
                      </Button>
                      <div className="d-flex flex-wrap justify-content-around align-items-center">
                        {imageList?.map((image, index) => (
                          <div key={index} className="image-item mt-3">
                            <img
                              src={image['data_url'] || image?.image}
                              alt=""
                              width="100"
                              height="100"
                              style={{ objectFit: 'cover', borderRadius: '50%', border: '1px solid #2e3f6e' }}
                            />
                            <div
                              className="image-item__btn-wrapper mt-2"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '10px',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                className="btn btn-succes cursor-pointer"
                                style={{ width: '90%' }}
                                onClick={() => onImageUpdate(index, image?.id)}
                              >
                                <EditIcon />
                              </div>
                              <div
                                style={{ width: '90%' }}
                                className="btn btn-danger cursor-pointer"
                                onClick={() => onImageRemoves(index, image)}
                              >
                                <DeleteIcon />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Box>
                  )}
                </ImageUploading>
              </Box>

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Edit Listing
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
